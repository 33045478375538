import avatar0 from "./users/avatar-1.jpg"
import avatar1 from "./users/sara_hall.png"
import avatar2 from "./users/avatar-2.jpg"
import avatar3 from "./users/avatar-3.jpg"
import avatar4 from "./users/avatar-4.jpg"
import avatar5 from "./users/avatar-5.jpg"
import avatar6 from "./users/avatar-6.jpg"
import avatar7 from "./users/avatar-7.jpg"
import avatar8 from "./users/avatar-8.jpg"
import img1 from "./product/img-1.png"
import img2 from "./product/img-2.png"
import img3 from "./product/img-3.png"
import img4 from "./product/img-4.png"
import img5 from "./product/Pro-8.png"
import img6 from "./product/img-6.png"
import img7 from "./product/img-7.png"
import product7 from "./product/Pro-1a.png"
import product8 from "./product/Pro-1b.png"
import client1 from "./clients/1.png"
import client2 from "./clients/2.png"
import client3 from "./clients/3.png"
import client4 from "./clients/4.png"
import client5 from "./clients/5.png"
import client6 from "./clients/6.png"

export default {
  avatar0,
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  product7,
  product8,
  client1,
  client2,
  client3,
  client4,
  client5,
  client6
}
