import React,{Component} from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { map } from "lodash";
import { Badge, Card, CardBody, Col } from "reactstrap";
import images from "assets/images";
import companies from "assets/images/companies";

class CardProject extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        {map(this.props.projects, (project, dkey) => (
          <Col xl="4" sm="6" key={"__projectcd_" + dkey}>
            <Card >
              <CardBody className="align-items-center">
                <div className="d-flex align-items-center">
                  <div className="avatar-md me-4">
                    <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                      <img src={companies[project.img]} alt="" height="100%" />
                    </span>
                  </div>

                  <div className="flex-grow-1 overflow-hidden align-items-center">
                    <h5 className="text-truncate font-size-15">

                        {project.name}
    
                    </h5>
                    <p className="text-muted mb-4">{project.description}</p>

                    
                  </div>
                </div>
              </CardBody>
              <div className="px-4 py-3 border-top">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item me-3">
                    <Badge
                      color={project.color}
                      className={"bg-" + project.color}
                    >
                      {project.status}
                    </Badge>
                  </li>{" "}
                  <li className="list-inline-item me-3" id="comments">
                    <i className="bx bx-detail me-1" />{" "}
                    Documentation
                  </li>
                  <li className="list-inline-item me-3" id="comments">
                    <i className="bx bx-chat me-1" />{" "}
                    Get Help
                  </li>
                </ul>
              </div>
            </Card>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

CardProject.propTypes = {
  projects: PropTypes.array,
};

export default CardProject;
