import React, { Component } from "react"
import { Container, Row } from "reactstrap"
import MetaTags from 'react-meta-tags';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import BlogGrid from "./BlogGrid"
import RightBar from "../BlogList/RightBar"
// window.Highcharts = null;
export default class index extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Resources | Be more informed</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Strive" breadcrumbItem="Resources Grid" />
            <Row>
              <BlogGrid />
              <RightBar />
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}