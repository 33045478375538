import React, { Component } from "react"
import { Card, CardBody, CardTitle, Media } from "reactstrap"
import { Link } from "react-router-dom"

const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

const today = new Date();
const yesterday = new Date();
yesterday.setDate(yesterday.getDate()-1);

const todayminus2 = new Date();
todayminus2.setDate(todayminus2.getDate()-2);

const todayminus3 = new Date();
todayminus3.setDate(todayminus3.getDate()-3);


const todayFormatted = (String(today.getDate()).padStart(2, '0')).concat(' ', month[today.getMonth()]);
const yesterdayFormatted = (String(yesterday.getDate()).padStart(2, '0')).concat(' ', month[yesterday.getMonth()]);
const todayminus2Formatted = (String(todayminus2.getDate()).padStart(2, '0')).concat(' ', month[todayminus2.getMonth()]);
const todayminus3Formatted = (String(todayminus3.getDate()).padStart(2, '0')).concat(' ', month[todayminus3.getMonth()]);

class ActivityComp extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

 

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle className="mb-5 h4">Priority Tasks</CardTitle>
            <ul className="verti-timeline list-unstyled">
              <li className="event-list active">
                <div className="event-timeline-dot">
                <i className="bx bx-check-circle font-size-18 text-danger" />
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <h5 className="font-size-14">
                      06/10/2019{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2 text-danger" />
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div>Call Marie Orlyn at Bank of America about their $3,005,342 engagement <span className="bg-danger badge bg-danger">Overdue</span></div>
                  </div>
                </div>
              </li>

              <li className="event-list">
                <div className="event-timeline-dot">
                  <i className="bx bx-check-circle font-size-18 text-danger" />
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <h5 className="font-size-14">
                    06/10/2019{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2 text-danger" />
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div id="activitytext">
                        Review Elon Misk performance improvement plan
                    </div>
                    <div>Prepare for POC readout: Chase CISO (Page Erwin)</div>
                  </div>
                </div>
              </li>
              <li className="event-list">
                <div className="event-timeline-dot">
                <i className="bx bx-check-circle font-size-18 text-danger" />
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <h5 className="font-size-14">
                    06/11/2019{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2 text-danger" />
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div>Budget Meeting: Summarize utilization VS headcount</div>
                    <div>Tech Loss analysis</div>
                  </div>
                </div>
              </li>
              <li className="event-list">
                <div className="event-timeline-dot">
                  <i className="bx bxs-check-circle font-size-18 text-success" />
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <h5 className="font-size-14">
                    06/01/2019{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2  text-success" />
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div>QBR Prep: work with Jillian Tatum on JPMC</div>
                    <div>QBR Prep: summarize KPIs</div>
                  </div>
                </div>
              </li>
            </ul>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default ActivityComp
