import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';

import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react"
import { connect } from "react-redux"
import LightData from "./LightData"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const LoadingContainer = () => <div>Loading...</div>

class MapsGoogle extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    }
    this.onMarkerClick = this.onMarkerClick.bind(this)
  }

  onMarkerClick() {
    alert("You clicked in this marker")
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content"  style={{ height: "1100px" }}>
          <MetaTags>
            <title>Customer Locations</title>
          </MetaTags>
          <div className="container-fluid"  style={{ height: "1100px" }}>
            <Breadcrumbs title="Maps" breadcrumbItem="Locations" />
            <Row   style={{ height: "1100px" }}>
            <Col >
                <Card style={{ height: "1100px" }} >
                  <CardBody>
                    <CardTitle className="h4">Where are our customers?</CardTitle>
                    <p className="card-title-desc">
                      Explore the map to find out.
                    </p>
                    <div
                      id="gmaps-markers"
                      className="gmaps"
                      style={{ position: "relative", height: "100%"  }}
                    >
                      <Map
                        google={this.props.google}
                        style={{ width: "100%", height: "850px" }}
                        zoom={14}
                      >
                        <Marker
                          title={"The marker`s title will appear as a tooltip."}
                          name={"SOMA"}
                          position={{ lat: 37.778519, lng: -122.40564 }}
                        />
                        <Marker name={"Dolores park"} />
                        <InfoWindow>
                          <div>
                            <h1>{this.state.selectedPlace.name}</h1>
                          </div>
                        </InfoWindow>
                      </Map>
                    </div>
                  </CardBody>
                </Card>
                </Col>
     </Row>

     </div>

        </div>
      </React.Fragment>
    )
  }
}

MapsGoogle.propTypes = {
  google: PropTypes.object
}

export default connect(
  null,
  {}
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    LoadingContainer: LoadingContainer,
    v: "3",
  })(MapsGoogle)
)
