/***
 * Events data
 *
 */
const events = [
  {
    id: 1,
    title: "CRM Update",
    start: new Date().setDate(new Date().getDate()),
    className: "bg-light text-dark",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 2,
    title: "Prospecting Block: West Coast",
    start: new Date().setDate(new Date().getDate()),
    className: "bg-danger text-light",
  },
  {
    id: 3,
    title: "Prospecting Block: East Coast",
    start: new Date().setDate(new Date().getDate() + 1),
    className: "bg-danger text-light",
  },
  {
    id: 4,
    title: "Prospecting Block: Central",
    start: new Date().setDate(new Date().getDate() + 2),
    className: "bg-danger text-light",
  },
  {
    id: 5,
    title: "Prospecting Block: Canada",
    start: new Date().setDate(new Date().getDate() + 3),
    className: "bg-danger text-light",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 6,
    title: "Account Research: target west market",
    start: new Date().setDate(new Date().getDate() - 1),
    className: "bg-light text-dark",
  },
  {
    id: 7,
    title: "Customer Meeting: Oracle",
    start: new Date().setDate(new Date().getDate() - 1),
    className: "bg-warning text-dark",
  },
  {
    id: 8,
    title: "Coaching Call: Odilia Alexandra",
    start: new Date().setDate(new Date().getDate() - 2),
    className: "bg-primary text-light",
  },
  {
    id: 9,
    title: "1-1: Amethyst Maya",
    start: new Date().setDate(new Date().getDate() - 2),
    className: "bg-primary text-light",
  },
  {
    id: 10,
    title: "Customer Meeting: Macy's",
    start: new Date().setDate(new Date().getDate() - 3),
    className: "bg-warning text-dark",
  },
  {
    id: 11,
    title: "Customer Meeting: Boscov's follow-up",
    start: new Date().setDate(new Date().getDate() - 3),
    className: "bg-warning text-dark",
  },
  {
    id: 12,
    title: "Account Research: Retail",
    start: new Date().setDate(new Date().getDate() - 3),
    className: "bg-light text-dark",
  },
  {
    id: 13,
    title: "Call Blitz",
    start: new Date().setDate(new Date().getDate() - 4),
    className: "bg-success text-light",
  },
  {
    id: 14,
    title: "Call Blitz",
    start: new Date().setDate(new Date().getDate() - 5),
    className: "bg-success text-light",
  },
  {
    id: 15,
    title: "Account Research: Software Companies",
    start: new Date().setDate(new Date().getDate() - 6),
    className: "bg-light text-dark",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 16,
    title: "CRM Update",
    start: new Date().setDate(new Date().getDate() - 7),
    className: "bg-light text-dark",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 17,
    title: "1-1: Amethyst Maya",
    start: new Date().setDate(new Date().getDate() - 7),
    className: "bg-primary text-white",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 18,
    title: "Sales Expert conference",
    start: new Date().setDate(new Date().getDate() - 9),
    className: "bg-success text-light",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 19,
    title: "Prospecting Block",
    start: new Date().setDate(new Date().getDate() - 10),
    className: "bg-light text-dark",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 20,
    title: "CRM Update",
    start: new Date().setDate(new Date().getDate() - 12),
    className: "bg-light text-dark",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 21,
    title: "Customer Meeting: Amtrack",
    start: new Date().setDate(new Date().getDate() - 12),
    className: "bg-warning text-dark",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 22,
    title: "Prospecting Block",
    start: new Date().setDate(new Date().getDate() - 13),
    className: "bg-danger text-light",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 23,
    title: "1-1: Amethyst Maya",
    start: new Date().setDate(new Date().getDate() - 14),
    className: "bg-primary text-light",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 24,
    title: "Customer Meeting: Microsoft",
    start: new Date().setDate(new Date().getDate() - 15),
    className: "bg-warning text-dark",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 25,
    title: "Call Blitz",
    start: new Date().setDate(new Date().getDate() - 16),
    className: "bg-success text-light",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 26,
    title: "Customer Meeting: Bank of America",
    start: new Date().setDate(new Date().getDate() - 17),
    className: "bg-warning text-dark",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 27,
    title: "Coaching call: Jason Ezekiel",
    start: new Date().setDate(new Date().getDate() - 18),
    className: "bg-primary text-light",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 28,
    title: "Call Blitz",
    start: new Date().setDate(new Date().getDate() - 18),
    className: "bg-success text-light",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 29,
    title: "Prospecting Block",
    start: new Date().setDate(new Date().getDate() - 19),
    className: "bg-danger text-light",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 30,
    title: "Account Research",
    start: new Date().setDate(new Date().getDate() - 20),
    className: "bg-light text-dark",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 31,
    title: "Prospecting Block",
    start: new Date().setDate(new Date().getDate() - 22),
    className: "bg-danger text-light",
    icon: "mdi mdi-run me-2 vertical-middle"
  },
  {
    id: 32,
    title: "Prospecting Block",
    start: new Date().setDate(new Date().getDate() - 24),
    className: "bg-danger text-light",
    icon: "mdi mdi-face-woman-shimmer-outline me-2 vertical-middle"
  },
];

/**
 * Calendar categories data
 */
const calenderDefaultCategories = [
  {
    id: 1,
    title: "Customer Meeting",
    type: "bg-warning",
    icon: "mdi mdi-face-woman-shimmer-outline me-2 vertical-middle"
  },
  {
    id: 2,
    title: "Prospecting Block",
    type: "bg-danger text-light",
    icon: "mdi mdi-bell-off me-2 vertical-middle"
  },
  {
    id: 3,
    title: "Cold Call",
    type: "bg-success",
    icon: "mdi mdi-cellphone-sound me-2 vertical-middle"
  },
  {
    id: 4,
    title: "Account Research",
    type: "bg-light",
    icon: "mdi mdi-magnify me-2 vertical-middle"
  },
  {
    id: 5,
    title: "Other",
    type: "bg-primary text-light",
    icon: "mdi mdi-calendar-range me-2 vertical-middle"
  },
];

export { calenderDefaultCategories, events };
