const tasks = [
   {
    id: 1,
    title: "POC",
    tasks: [
      {
        id: 21,
        description: "Chase - Core IT",
        members: [
          {
            username: "",
            userImg: "client2",
          },
        ],
        status: "Blocked",
        statusClass: "blocked",
        budget: "$198,015",
      },
      {
        id: 11,
        description: "BoA - Security Team",
        members: [
          {
            username: "",
            userImg: "client1",
          },
        ],
        status: "Not Started",
        statusClass: "notstarted",
        budget: "$145,015",
      }
    ],
  },
  {
    id: 2,
    title: "Tech Win",
    tasks: [
      {
        id: 31,
        description: "Capital One - Core IT",
        members: [
          {
            username: "",
            userImg: "client3",
          },
        ],
        status: "Tech Win",
        statusClass: "won",
        budget: "$15,000",
      },
    ],
  },
  {
    id: 3,
    title: "Tech Loss",
    tasks: [
      {
        id: 41,
        description: "HSBC - Security Team",
        members: [
          {
            username: "",
            userImg: "client4",
          },
        ],
        status: "Tech Lost",
        statusClass: "lost",
        budget: "$1,324,000",
      },
      {
        id: 42,
        description: "Wells Fargo - Core IT",
        members: [
          {
            
            userImg: "client5",
          }
        ],
        status: "Tech Lost",
        statusClass: "lost",
        budget: "$1,232,000",
      },
      {
        id: 43,
        description: "Ally - Core IT",
        members: [
          {
            username: "",
            userImg: "client6",
          },
        ],
        status: "Tech Lost",
        statusClass: "lost",
        budget: "$950,000",
      },
      {
        id: 44,
        description: "BoA - Core IT",
        members: [
          {
            username: "",
            userImg: "client1",
          },
        ],
        status: "Tech Lost",
        statusClass: "lost",
        budget: "$750,000",
      },
    ],
  },
]

const series = [
  {
    name: "Complete Tasks",
    type: "column",
    data: [23, 11, 22, 27, 13, 22, 52, 21, 44, 22, 30],
  },
  {
    name: "All Tasks",
    type: "line",
    data: [23, 11, 34, 27, 17, 22, 62, 32, 44, 22, 39],
  },
]

const options = {
  chart: { height: 280, type: "line", stacked: !1, toolbar: { show: !1 } },
  stroke: { width: [0, 2, 5], curve: "smooth" },
  plotOptions: { bar: { columnWidth: "20%", endingShape: "rounded" } },
  colors: ["#50a5f1", "#20DBCD"],
  fill: {
    gradient: {
      inverseColors: !1,
      shade: "light",
      type: "vertical",
      opacityFrom: 0.85,
      opacityTo: 0.55,
      stops: [0, 100, 100, 100],
    },
  },
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
  ],
  markers: { size: 0 },
  yaxis: { min: 0 },
}

const statusClasses = {
  inprogress: "badge-soft-primary",
  notstarted: "badge-soft-secondary",
  won: "badge-soft-success",
  lost: "badge-soft-danger",
  blocked: "badge-soft-warning",
}

export { tasks, series, options, statusClasses }
