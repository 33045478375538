import React, { Component } from "react"
import {
  Card,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"

import img1 from "../../../assets/images/small/img-2.jpg"
import img2 from "../../../assets/images/small/img-6.jpg"
import img3 from "../../../assets/images/small/img-1.jpg"
import img4 from "../../../assets/images/small/img-3.jpg"
import { Link } from "react-router-dom"

export default class BlogGrid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
    }
    this.toggleTab = this.toggleTab.bind(this)
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  render() {
    return (
      <React.Fragment>
        <Col xl={9} lg={8}>
          <Card>

            <TabContent className="p-4" activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div>
                  <Row className="justify-content-center">
                    <Col xl={8}>
                      <div>
                        <Row className="align-items-center">
                          <div className="col-4">
                            <div>
                              <h5 className="mb-0">Resources</h5>
                            </div>
                          </div>

                          <Col xs={8}>
                            <div>
                              <ul className="nav nav-pills justify-content-end">
                                <NavItem>
                                  <NavLink
                                    className="disabled"
                                    to="#"
                                    tabIndex="-1"
                                  >
                                    View :
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <Link className="nav-link" to="blog-list">
                                    <i className="mdi mdi-format-list-bulleted"></i>
                                  </Link>
                                </NavItem>
                                <NavItem>
                                  <Link
                                    to="blog-grid "
                                    className="nav-link active"
                                  >
                                    <i className="mdi mdi-view-grid-outline"></i>
                                  </Link>
                                </NavItem>
                              </ul>
                            </div>
                          </Col>
                        </Row>

                        <hr className="mb-4" />

                        <Row>
                          <Col sm={6}>
                            <Card className="p-1 border shadow-none">
                              <div className="p-3">
                                <h5>
                                  <Link to="/blog-details" className="text-dark">
                                    10 Secrets to Delivering a Great Product Demo
                                  </Link>
                                </h5>
                                <p className="text-muted mb-0">10 Jan, 2022</p>
                              </div>

                              <div className="position-relative">
                                <img
                                  src={img1}
                                  alt=""
                                  className="img-thumbnail"
                                />
                              </div>

                              <div className="p-3">
                                <ul className="list-inline">
                                  <li className="list-inline-item me-3">
                                    <Link to="#" className="text-muted">
                                      <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                      Closing
                                    </Link>
                                  </li>
                                  <li className="list-inline-item me-3">
                                    <Link to="#" className="text-muted">
                                      <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                                      12 Comments
                                    </Link>
                                  </li>
                                </ul>
                                <p>
                                Ah, my first software demo–I remember it like it was yesterday (it was four years ago). Somewhere out there is a shell-shocked prospect wondering why on earth they even took my call ...
                                </p>

                                <div>
                                  <Link to="#" className="text-primary">
                                    Read more{" "}
                                    <i className="mdi mdi-arrow-right"></i>
                                  </Link>
                                </div>
                              </div>
                            </Card>
                          </Col>

                          <Col sm={6}>
                            <Card className="p-1 border shadow-none">
                              <div className="p-3">
                                <h5>
                                  <Link to="/blog-details" className="text-dark">
                                    Sales Kick-off recording
                                  </Link>
                                </h5>
                                <p className="text-muted mb-0">22 Dec, 2021</p>
                              </div>

                              <div className="position-relative">
                                <img
                                  src={img2}
                                  alt=""
                                  className="img-thumbnail"
                                />

                                <div className="blog-play-icon">
                                  <Link
                                    to="#"
                                    className="avatar-sm d-block mx-auto"
                                  >
                                    <span className="avatar-title rounded-circle font-size-18">
                                      <i className="mdi mdi-play"></i>
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="p-3">
                                <ul className="list-inline">
                                  <li className="list-inline-item me-3">
                                    <Link to="#" className="text-muted">
                                      <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                      Prospecting
                                    </Link>
                                  </li>
                                  <li className="list-inline-item me-3">
                                    <Link to="#" className="text-muted">
                                      <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                                      08 Comments
                                    </Link>
                                  </li>
                                </ul>

                                <p>
                                REview the recording of our latest kick-off to be the key to your success! The recording includes ...
                                </p>

                                <div>
                                  <Link to="#" className="text-primary">
                                    Read more{" "}
                                    <i className="mdi mdi-arrow-right"></i>
                                  </Link>
                                </div>
                              </div>
                            </Card>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={6}>
                            <Card className="p-1 border shadow-none">
                              <div className="p-3">
                                <h5>
                                  <Link to="/blog-details" className="text-dark">
                                    Customer Stories
                                  </Link>
                                </h5>
                                <p className="text-muted mb-0">10 Jan, 2020</p>
                              </div>

                              <div className="position-relative">
                                <img
                                  src={img3}
                                  alt=""
                                  className="img-thumbnail"
                                />
                              </div>

                              <div className="p-3">
                                <ul className="list-inline">
                                  <li className="list-inline-item me-3">
                                    <Link to="#" className="text-muted">
                                      <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                      MEDDPICC
                                    </Link>
                                  </li>
                                  <li className="list-inline-item me-3">
                                    <Link to="#" className="text-muted">
                                      <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                                      08 Comments
                                    </Link>
                                  </li>
                                </ul>
                                <p>
                                Learn about how our customers use our products to excel at value selling ...
                                </p>

                                <div>
                                  <Link to="#" className="text-primary">
                                    Read more{" "}
                                    <i className="mdi mdi-arrow-right"></i>
                                  </Link>
                                </div>
                              </div>
                            </Card>
                          </Col>

                          <Col sm={6}>
                            <Card className="p-1 border shadow-none">
                              <div className="p-3">
                                <h5>
                                  <Link to="/blog-details" className="text-dark">
                                    10 steps to mastering the art of storytelling
                                  </Link>
                                </h5>
                                <p className="text-muted mb-0">22 Dec, 2021</p>
                              </div>

                              <div className="position-relative">
                                <img
                                  src={img4}
                                  alt=""
                                  className="img-thumbnail"
                                />
                              </div>

                              <div className="p-3">
                                <ul className="list-inline">
                                  <li className="list-inline-item me-3">
                                    <Link to="#" className="text-muted">
                                      <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                      Storytelling
                                    </Link>
                                  </li>
                                  <li className="list-inline-item me-3">
                                    <Link to="#" className="text-muted">
                                      <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                                      08 Comments
                                    </Link>
                                  </li>
                                </ul>

                                <p>
                                We love stories. A good story can convey a message, entertain or ignite a fire within your audience. As a tourism professional, we create experiences, which in turn ...
                                </p>

                                <div>
                                  <Link to="#" className="text-primary">
                                    Read more{" "}
                                    <i className="mdi mdi-arrow-right"></i>
                                  </Link>
                                </div>
                              </div>
                            </Card>
                          </Col>
                        </Row>

                        <hr className="my-4" />

                        <div className="text-center">
                          <ul className="pagination justify-content-center pagination-rounded">
                            <li className="page-item disabled">
                              <Link to="#" className="page-link">
                                <i className="mdi mdi-chevron-left"></i>
                              </Link>
                            </li>
                            <li className="page-item">
                              <Link to="#" className="page-link">
                                1
                              </Link>
                            </li>
                            <li className="page-item active">
                              <Link to="#" className="page-link">
                                2
                              </Link>
                            </li>
                            <li className="page-item">
                              <Link to="#" className="page-link">
                                3
                              </Link>
                            </li>
                            <li className="page-item">
                              <Link to="#" className="page-link">
                                ...
                              </Link>
                            </li>
                            <li className="page-item">
                              <Link to="#" className="page-link">
                                10
                              </Link>
                            </li>
                            <li className="page-item">
                              <Link to="#" className="page-link">
                                <i className="mdi mdi-chevron-right"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </TabPane>

              <TabPane tabId="2">
                <div>
                  <Row className="justify-content-center">
                    <Col xl={8}>
                      <h5>Archive</h5>

                      <div className="mt-5">
                        <div className="d-flex flex-wrap">
                          <div className="me-2">
                            <h4>2020</h4>
                          </div>
                          <div className="ms-auto">
                            <span className="badge badge-soft-success badge-pill float-right ms-1 font-size-12">
                              03
                            </span>
                          </div>
                        </div>
                        <hr className="mt-2" />

                        <div className="list-group list-group-flush">
                          <Link
                            to="/blog-details"
                            className="list-group-item text-muted"
                          >
                            <i className="mdi mdi-circle-medium me-1"></i>{" "}
                            10 Secrets to Delivering a Great Product Demo
                          </Link>

                          <Link
                            to="/blog-details"
                            className="list-group-item text-muted"
                          >
                            <i className="mdi mdi-circle-medium me-1"></i>{" "}
                            Sales Kick-off recording
                          </Link>

                          <Link
                            to="/blog-details"
                            className="list-group-item text-muted"
                          >
                            <i className="mdi mdi-circle-medium me-1"></i>{" "}
                            10 steps to mastering the art of storytelling
                          </Link>
                        </div>
                      </div>

                      <div className="mt-5">
                        <div className="d-flex flex-wrap">
                          <div className="me-2">
                            <h4>2019</h4>
                          </div>
                          <div className="ms-auto">
                            <span className="badge badge-soft-success badge-pill float-right ms-1 font-size-12">
                              06
                            </span>
                          </div>
                        </div>
                        <hr className="mt-2" />

                        <div className="list-group list-group-flush">
                          <Link
                            to="/blog-details"
                            className="list-group-item text-muted"
                          >
                            <i className="mdi mdi-circle-medium me-1"></i>{" "}
                            Coffee with Friends
                          </Link>

                          <Link
                            to="/blog-details"
                            className="list-group-item text-muted"
                          >
                            <i className="mdi mdi-circle-medium me-1"></i> Neque
                            porro quisquam est
                          </Link>

                          <Link
                            to="/blog-details"
                            className="list-group-item text-muted"
                          >
                            <i className="mdi mdi-circle-medium me-1"></i> Quis
                            autem vel eum iure
                          </Link>

                          <Link
                            to="/blog-details"
                            className="list-group-item text-muted"
                          >
                            <i className="mdi mdi-circle-medium me-1"></i> Cras
                            mi eu turpis
                          </Link>

                          <Link
                            to="/blog-details"
                            className="list-group-item text-muted"
                          >
                            <i className="mdi mdi-circle-medium me-1"></i>{" "}
                            Sales Kick-off recording
                          </Link>

                          <Link
                            to="/blog-details"
                            className="list-group-item text-muted"
                          >
                            <i className="mdi mdi-circle-medium me-1"></i>{" "}
                            10 steps to mastering the art of storytelling
                          </Link>
                        </div>
                      </div>

                      <div className="mt-5">
                        <div className="d-flex flex-wrap">
                          <div className="me-2">
                            <h4>2018</h4>
                          </div>
                          <div className="ms-auto">
                            <span className="badge badge-soft-success badge-pill float-right ms-1 font-size-12">
                              03
                            </span>
                          </div>
                        </div>
                        <hr className="mt-2" />

                        <div className="list-group list-group-flush">
                          <Link
                            to="/blog-details"
                            className="list-group-item text-muted"
                          >
                            <i className="mdi mdi-circle-medium me-1"></i>{" "}
                            10 Secrets to Delivering a Great Product Demo
                          </Link>

                          <Link
                            to="/blog-details"
                            className="list-group-item text-muted"
                          >
                            <i className="mdi mdi-circle-medium me-1"></i>{" "}
                            Sales Kick-off recording
                          </Link>

                          <Link
                            to="/blog-details"
                            className="list-group-item text-muted"
                          >
                            <i className="mdi mdi-circle-medium me-1"></i>{" "}
                            10 steps to mastering the art of storytelling
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </TabPane>
            </TabContent>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}
