const users = [
  {
    id: 1,
    img: "avatar4",
    name: "Odilia Alexandra",
    designation: "odilia.alexandra@strive.com",
    color: "primary",
    email: "dale@skote.com",
    projects: "125",
    tags: ["SSN: 038-64-3017"],
  },
  {
    id: 2,
    img: "avatar2",
    name: "Mat Lainey",
    designation: "mat.lainey@strive.com",
    email: "frank@skote.com",
    projects: "132",
    tags: ["SSN: 501-60-3910"],
  },
  {
    id: 3,
    img: "avatar3",
    name: "Adalyn Magdalene",
    designation: "Storyteller",
    email: "Rafael@skote.com",
    projects: "1112",
    tags: ["SSN: 320-48-2603"],
  },
  {
    id: 4,
    img: "avatar0",
    name: "Amethyst Maya",
    designation: "amethyst.maya@strive.com",
    color: "success",
    email: "mark@skote.com",
    projects: "121",
    tags: ["SSN: 035-22-8008"],
  },
  {
    id: 5,
    img: "avatar5",
    name: "Jason Ezekiel",
    designation: "jason.ezekiel@strive.com",
    email: "minnie@skote.com",
    projects: "145",
    tags: ["SSN: 046-03-4895"],
  },
  {
    id: 6,
    img: "avatar6",
    name: "Florrie Lesly",
    designation: "florrie.lesly@strive.com",
    email: "shirley@skote.com",
    projects: "136",
    tags: ["SSN: 445-60-7398"],
  },
  {
    id: 7,
    img: "avatar7",
    name: "Sinjin Deacon",
    designation: "sinjin.deacon@strive.com",
    color: "info",
    email: "john@skote.com",
    projects: "125",
    tags: ["SSN: 511-22-8072"],
  },
  {
    id: 8,
    img: "avatar8",
    name: "Jane Doe",
    designation: "jane.doe@strive.com",
    color: "",
    email: "colin@skote.com",
    projects: "136",
    tags: ["SSN: 	310-27-1380"],
  }
]

const userProfile = {
  id: 1,
  name: "Cynthia Price",
  designation: "Author",
  img: "avatar1",
  projectCount: 125,
  revenue: 1245,
  personalDetail:
    "Hi I'm Cynthia Price,has been the industry's standard dummy text To an English person, it will seem like simplified English, as a skeptical Cambridge.",
  phone: "(123) 123 1234",
  email: "cynthiaskote@gmail.com",
  location: "California, United States",
  experiences: [
    {
      id: 1,
      iconClass: "bx-server",
      link: "#",
      designation: "Back end Developer",
      timeDuration: "2016 - 19",
    },
    {
      id: 2,
      iconClass: "bx-code",
      link: "#",
      designation: "Front end Developer",
      timeDuration: "2013 - 16",
    },
    {
      id: 3,
      iconClass: "bx-edit",
      link: "#",
      designation: "UI /UX Designer",
      timeDuration: "2011 - 13",
    },
  ],
  projects: [
    {
      id: 1,
      name: "Skote admin UI",
      startDate: "2 Sep, 2019",
      deadline: "20 Oct, 2019",
      budget: "$506",
    },
    {
      id: 2,
      name: "Skote admin Logo",
      startDate: "1 Sep, 2019",
      deadline: "2 Sep, 2019",
      budget: "$94",
    },
    {
      id: 3,
      name: "Redesign - Landing page",
      startDate: "21 Sep, 2019",
      deadline: "29 Sep, 2019",
      budget: "$156",
    },
    {
      id: 4,
      name: "App Landing UI",
      startDate: "29 Sep, 2019",
      deadline: "04 Oct, 2019",
      budget: "$122",
    },
    {
      id: 5,
      name: "Blog Template",
      startDate: "05 Oct, 2019",
      deadline: "16 Oct, 2019",
      budget: "$164",
    },
    {
      id: 6,
      name: "Redesign - Multipurpose Landing",
      startDate: "17 Oct, 2019",
      deadline: "05 Nov, 2019",
      budget: "$192",
    },
    {
      id: 7,
      name: "Logo Branding",
      startDate: "04 Nov, 2019",
      deadline: "05 Nov, 2019",
      budget: "$94",
    },
  ],
}

export { users, userProfile }
