const inboxmails = [
  {
    id: 1,
    name: "John, me (3)",
    description: "<a class='subject' href='#'> <span class='bg-danger badge me-2'> External </span> Bank of America POC – <span class='teaser'>Follow up from today's discussion</span></a>",
    read: false,
    fav: false,
    date: '06/10/2019',
  },
  {
    id: 2,
    name: "me, Susanna (7)",
    description: "<a class='subject' href='#'> <span class='bg-danger badge me-2'> External </span> Chase POC – <span class='teaser'>Please do the needful</span></a>",
    read: false,
    fav: false,
    date: '06/10/2019',
  },
  {
    id: 3,
    name: "me, Jane (7)",
    description: "<a href='#' class='subject'> Re: New app settings – <span class='teaser'> Will you answer him asap? </span></a>",
    read: true,
    fav: false,
    date: '06/09/2019',
  },
  {
    id: 4,
    name: "me, Peter (2)",
    description: "<a href='#' class='subject'> <span class='bg-info badge me-2'> Team </span> Off on Thursday - <span class='teaser'> Sent from my iPhone 4 4 mar 2014 at 5:55 pm </span></a>",
    read: true,
    fav: false,
    date: 'Mar 4',
  },
  {
    id: 5,
    name: "Medium",
    description: "<a href='#' class='subject'> <span class='bg-primary badge me-2'> Social </span> This Week's Top Stories – <span class='teaser'> Our top pick for you on Medium this week The Man Who Destroyed America’s Ego </span></a>",
    read: true,
    fav: false,
    date: 'Feb 28',
  },
]

const starredmails = [
  {
    id: 1,
    name: "Charukaw, me (7)",
    description: "<a href='#' class='subject'> Hey man – <span class='teaser'> Nah man sorry i don't. Should i get it? </span></a>",
    read: true,
    fav: false,
    date: 'Feb 23',
  },
]

const importantmails = [
  {
    id: 1,
    name: "Revibe",
    description: "<a href='#' class='subject'> <span class='bg-danger badge me-2'> Friends </span> Weekend on Revibe – <span class='teaser'> Today's Friday and we thought maybe you want some music inspiration for the weekend. Here are some trending tracks and playlists we think you should give a listen! </span></a>",
    read: true,
    fav: false,
    date: 'Feb 27',
  },
  {
    id: 2,
    name: "Erik, me (5)",
    description: "<a href='#' class='subject'> Regarding our meeting – <span class='teaser'> That's great, see you on Thursday! </span></a>",
    read: true,
    fav: false,
    date: 'Feb 24',
  },
  {
    id: 3,
    name: "KanbanFlow",
    description: "<a href='#' class='subject'> <span class='bg-primary badge me-2'> Social </span> Task assigned: Clone ARP's website – <span class='teaser'> You have been assigned a task by Alex@Work on the board Web. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 24',
  },
  {
    id: 4,
    name: "Tobias Berggren",
    description: "<a href='#' class='subject'> Let's go fishing! – <span class='teaser'> Hey, You wanna join me and Fred at the lake tomorrow? It'll be awesome. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 23',
  },
  {
    id: 5,
    name: "Charukaw, me (7)",
    description: "<a href='#' class='subject'> Hey man – <span class='teaser'> Nah man sorry i don't. Should i get it? </span></a>",
    read: true,
    fav: false,
    date: 'Feb 23',
  },
  {
    id: 6,
    name: "me, Peter (5)",
    description: "<a href='#' class='subject'> <span class='bg-info badge me-2'> Support </span> Home again! – <span class='teaser'> That's just perfect! See you tomorrow. </span></a>",
    read: false,
    fav: false,
    date: 'Feb 21',
  },
]

const draftmails = [
  {
    id:1,
    name: "Erik, me (5)",
    description: "<a href='#' class='subject'> Regarding our meeting – <span class='teaser'> That's great, see you on Thursday! </span></a>",
    read: true,
    fav: false,
    date: 'Feb 24',
  },
]

const sentmails = [
  {
    id: 1,
    name: "Infinity HR",
    description: "<a href='#' class='subject'> Sveriges Hetaste sommarjobb – <span class='teaser'> Hej Nicklas Sandell! Vi vill bjuda in dig till 'First tour 2014', ett rekryteringsevent som erbjuder jobb på 16 semesterorter iSverige. </span></a>",
    read: true,
    fav: false,
    date: 'Mar 8',
  },
  {
    id: 1,
    name: "Revibe",
    description: "<a href='#' class='subject'> <span class='bg-danger badge me-2'> Friends </span> Weekend on Revibe – <span class='teaser'> Today's Friday and we thought maybe you want some music inspiration for the weekend. Here are some trending tracks and playlists we think you should give a listen! </span></a>",
    read: true,
    fav: false,
    date: 'Feb 27',
  },
  {
    id: 1,
    name: "Erik, me (5)",
    description: "<a href='#' class='subject'> Regarding our meeting – <span class='teaser'> That's great, see you on Thursday! </span></a>",
    read: true,
    fav: false,
    date: 'Feb 24',
  },
  {
    id: 4,
    name: "KanbanFlow",
    description: "<a href='#' class='subject'> <span class='bg-primary badge me-2'> Social </span> Task assigned: Clone ARP's website – <span class='teaser'> You have been assigned a task by Alex@Work on the board Web. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 24',
  },
  {
    id: 5,
    name: "Tobias Berggren",
    description: "<a href='#' class='subject'> Let's go fishing! – <span class='teaser'> Hey, You wanna join me and Fred at the lake tomorrow? It'll be awesome. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 23',
  },
  {
    id: 6,
    name: "Charukaw, me (7)",
    description: "<a href='#' class='subject'> Hey man – <span class='teaser'> Nah man sorry i don't. Should i get it? </span></a>",
    read: true,
    fav: false,
    date: 'Feb 23',
  },
  {
    id: 7,
    name: "me, Peter (5)",
    description: "<a href='#' class='subject'> <span class='bg-info badge me-2'> Support </span> Home again! – <span class='teaser'> That's just perfect! See you tomorrow. </span></a>",
    read: false,
    fav: false,
    date: 'Feb 21',
  },
  {
    id: 8,
    name: "Stack Exchange",
    description: "<a href='#' class='subject'> 1 new items in your Stackexchange inbox – <span class='teaser'> The following items were added to your Stack Exchange global inbox since you last checked it. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 21',
  },
]

const trashmails = [

  {
    id: 1,
    name: "Tobias Berggren",
    description: "<a href='#' class='subject'> Let's go fishing! – <span class='teaser'> Hey, You wanna join me and Fred at the lake tomorrow? It'll be awesome. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 23',
  },
]

export { inboxmails, starredmails, importantmails, draftmails, sentmails, trashmails };