import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import classNames from "classnames"
import { Card, CardBody } from "reactstrap"
import { map, size } from "lodash"

//Import Images
import images from "assets/images"

import { statusClasses } from "common/data/tasks"

class CardTaskBox extends Component {
  render() {
    const { data } = this.props
    return (
      <React.Fragment>
        <Card className="task-box mb-4" style={{ width: "300px"}}>
          <CardBody className="board-width ">

            <div className="avatar-group float-start">
              {map(
                data.members,
                (member, index) =>
                  index < 2 && (
                    <div className="avatar-group-item"  key={index}>
                    <Link
                      to="#"
                      className="d-inline-block"
                
                    >
                      {member.userImg ? (
                        <img
                          src={images[member.userImg]}
                          className="rounded-circle avatar-md"
                          alt=""                       />
                      ) : (
                        <div className="avatar-md">
                          <span className="avatar-title rounded-circle bg-info text-white font-size-16">
                            {member.username.charAt(0)}
                          </span>
                        </div>
                      )}
                    </Link>
                    </div>
                  )
              )}
              {size(data.members) > 2 && (
                <div className="avatar-group-item">
                <Link to="#" className="d-inline-block">
                  <div className="avatar-md">
                    <span className="avatar-title rounded-circle bg-info text-white font-size-16">
                      {size(data.members) - 2} +
                    </span>
                  </div>
                </Link>
                </div>
              )}
            </div>

            <div className="text-end align-self-center align-middle">
              <h3 className="font-size-16 mb-1">{data.description}</h3>
              <h3 className="font-size-16 mb-1">{data.budget}</h3>
              <p className="mb-0 text-muted">Close date: 06/10/2019</p>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

CardTaskBox.propTypes = {
  data: PropTypes.object
}

export default CardTaskBox
