import React, { Component } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/sara_hall.png"
import profileImg from "../../assets/images/se-team.svg"

class WelcomeComp extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>


        
        <Card className="overflow-hidden">
        <CardBody>
              <div className="media">
                <div className="me-2">
                  <img
                    src={avatar1}
                    alt=""
                    className="avatar-xl pl-2 rounded-circle img-thumbnail"
                  />
                </div>
                <div className="media-body">
                  <div className="media">
                    <div className="media-body">
                      <div className="text-muted">
                        <h5 className="mb-1">Jenna Myers</h5>
                        <p className="mb-0">SE Manager</p>
                      </div>
                    </div>

                  </div>

                  <hr />

                  <Row>
                  <p className="text-muted mb-0">jenna.myers@strive.com</p>
               
                    <p className="text-muted mb-0">+1 (215) 234 9273</p>
                  </Row>
                </div>
              </div>
            </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default WelcomeComp