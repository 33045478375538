import React, { Component } from "react"
import { Card, CardBody, Col } from "reactstrap"
import { Link } from "react-router-dom"

// import images
import img1 from '../../../assets/images/small/img-2.jpg';
import img2 from '../../../assets/images/small/img-6.jpg';
import img3 from '../../../assets/images/small/img-3.jpg';

export default class RightBar extends Component {
  render() {
    return (
      <React.Fragment>
        <Col xl={3} lg={4}>
          <Card>
            <CardBody className="p-4">
              <div className="search-box">
                <p className="text-muted">Search</p>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control rounded bg-light border-light"
                    placeholder="Search..."
                  />
                  <i className="mdi mdi-magnify search-icon"></i>
                </div>
              </div>

              <hr className="my-4" />

              <div>
                <p className="text-muted">Categories</p>

                <ul className="list-unstyled fw-medium">

                  <li>
                    <a href="#" className="text-muted py-2 d-block">
                      <i className="mdi mdi-chevron-right me-1"></i> Prospecting{" "}
                      <span className="badge badge-soft-success badge-pill float-end ms-1 font-size-12">
                        04
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-muted py-2 d-block">
                      <i className="mdi mdi-chevron-right me-1"></i> Personalization
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-muted py-2 d-block">
                      <i className="mdi mdi-chevron-right me-1"></i> MEDDPICC
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-muted py-2 d-block">
                      <i className="mdi mdi-chevron-right me-1"></i> Closing
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-muted py-2 d-block">
                      <i className="mdi mdi-chevron-right me-1"></i> Storytelling
                      <span className="badge badge-soft-success badge-pill ms-1 float-end font-size-12">
                        12
                      </span>
                    </a>
                  </li>
                </ul>
              </div>



              <hr className="my-4" />

              <div>
                <p className="text-muted mb-2">Popular Posts</p>

                <div className="list-group list-group-flush">
                  <a href="#" className="list-group-item text-muted py-3 px-2">
                    <div className="media align-items-center">
                      <div className="me-3">
                        <img
                          src={img1}
                          alt=""
                          className="avatar-md h-auto d-block rounded"
                        />
                      </div>
                      <div className="media-body overflow-hidden">
                        <h5 className="font-size-13 text-truncate">
                          10 Secrets to Delivering a Great Product Demo
                        </h5>
                        <p className="mb-0 text-truncate">10 Jan, 2020</p>
                      </div>
                    </div>
                  </a>

                  <a href="#" className="list-group-item text-muted py-3 px-2">
                    <div className="media align-items-center">
                      <div className="me-3">
                        <img
                          src={img2}
                          alt=""
                          className="avatar-md h-auto d-block rounded"
                        />
                      </div>
                      <div className="media-body overflow-hidden">
                        <h5 className="font-size-13 text-truncate">
                          Sales Kick-off recording
                        </h5>
                        <p className="mb-0 text-truncate">22 Dec, 2021</p>
                      </div>
                    </div>
                  </a>

                  <a href="#" className="list-group-item text-muted py-3 px-2">
                    <div className="media align-items-center">
                      <div className="me-3">
                        <img
                          src={img3}
                          alt=""
                          className="avatar-md h-auto d-block rounded"
                        />
                      </div>
                      <div className="media-body overflow-hidden">
                        <h5 className="font-size-13 text-truncate">
                          10 steps to mastering the art of storytelling
                        </h5>
                        <p className="mb-0 text-truncate">11 Mar, 2020</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <hr className="my-4" />

              <div>
                <p className="text-muted">Tags</p>

                <div className="d-flex flex-wrap gap-2 widget-tag">
                  <div>
                    <Link to="#" className="badge bg-light font-size-12">
                      Prospecting
                    </Link>
                  </div>
                  <div>
                    <Link to="#" className="badge bg-light font-size-12">
                      Cross-training
                    </Link>
                  </div>
                  <div>
                    <Link to="#" className="badge bg-light font-size-12">
                      Business
                    </Link>
                  </div>
                  <div>
                    <Link to="#" className="badge bg-light font-size-12">
                      Closing
                    </Link>
                  </div>
                  <div>
                    <Link to="#" className="badge bg-light font-size-12">
                      Storytelling
                    </Link>
                  </div>
                  <div>
                    <Link to="#" className="badge bg-light font-size-12">
                      Lifestyle
                    </Link>
                  </div>
                  <div>
                    <Link to="#" className="badge bg-light font-size-12">
                      MEDDPICC
                    </Link>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}
