import React, { Component } from "react"
import { Radar } from "react-chartjs-2"

class RadarChart extends Component {
  render() {
    const data = {
      labels: [
        "Eating",
        "Drinking",
        "Sleeping",
        "Designing",
        "Coding",
        "Personalization",
        "Closing",
      ],
      datasets: [
        {
          label: "Desktops",
          backgroundColor: "rgba(52, 195, 143, 0.2)",
          borderColor: "#20DBCD",
          pointBackgroundColor: "#20DBCD",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "#20DBCD",
          data: [65, 59, 90, 81, 56, 55, 40],
        },
        {
          label: "Tablets",
          backgroundColor: "rgba(85, 110, 230, 0.2)",
          borderColor: "#50a5f1",
          pointBackgroundColor: "#50a5f1",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "#50a5f1",
          data: [28, 48, 40, 19, 96, 27, 100],
        },
      ],
    }

    return (
      <React.Fragment>
        <Radar width={474} height={300} data={data} />
      </React.Fragment>
    )
  }
}

export default RadarChart
