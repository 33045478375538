/**
 * Chat data
 */
const chats = [
  {
    id: 1,
    roomId: 1,
    status: "online",
    image: "avatar8",
    name: "Jane Doe",
    description: "Lorem Ipsum dolor sit amet",
    time: "06/10/2019",
  },
  {
    id: 2,
    roomId: 2,
    status: "online",
    image: "avatar8",
    name: "John Doe",
    description: "Lorem Ipsum dolor sit amet",
    time: "06/09/2019",
  },
  {
    id: 3,
    roomId: 3,
    status: "intermediate",
    image: "avatar8",
    name: "Jane Doe",
    description: "Lorem Ipsum dolor sit amet",
    time: "06/08/2019",
  },
  {
    id: 4,
    roomId: 4,
    status: "offline",
    image: "avatar8",
    name: "Jane Doe",
    description: "Lorem Ipsum dolor sit amet",
    time: "06/07/2019",
  },
]

/**
 * group list data
 */
const groups = [
  { id: 1, image: "G", name: "General" },
  { id: 2, image: "R", name: "Reporting" },
  { id: 3, image: "M", name: "Meeting" },
  { id: 4, image: "A", name: "Project A" },
  { id: 5, image: "B", name: "Project B" },
]

/**
 * contact list data
 */
const contacts = [
  {
    category: "A",
    child: [
      { id: 1, name: "Jason Ezekiel" },
      { id: 2, name: "Alfonso Fisher" },
    ],
  },
  {
    category: "B",
    child: [{ id: 1, name: "Bonnie Harney" }],
  },
  {
    category: "C",
    child: [
      { id: 1, name: "Charles Brown" },
      { id: 2, name: "Carmella Jones" },
      { id: 3, name: "Carrie Williams" },
    ],
  },
  {
    category: "D",
    child: [{ id: 4, name: "Dolores Minter" }],
  },
]

/**
 * message list data
 */
const messages = [
  {
    id: 1,
    roomId: 1,
    sender: "Jane Doe",
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    createdAt: "2020-04-02T17:07:21.529Z",
  },
  {
    id: 2,
    roomId: 1,
    sender: "Jenna Myers",
    message: "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    createdAt: "2020-04-02T17:07:21.529Z",
  },
  {
    id: 3,
    roomId: 1,
    sender: "Jane Doe",
    message: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    createdAt: "2020-04-02T17:07:21.529Z",
  },
  {
    id: 4,
    roomId: 2,
    sender: "John Doe",
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    createdAt: "2020-04-02T17:07:21.529Z",
  },
  {
    id: 5,
    roomId: 3,
    sender: "Jane Doe",
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    createdAt: "2020-04-02T17:07:21.529Z",
  },
  {
    id: 6,
    roomId: 4,
    sender: "Jane Doe",
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    createdAt: "2020-04-02T17:07:21.529Z",
  },
]

export { chats, messages, contacts, groups }
