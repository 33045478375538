import React, { Component } from "react";
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Input
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";


import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

//import action
import { getChartsData } from "../../store/actions";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import Comments from "./Comments";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";


import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_more from "highcharts/highcharts-more"; //module
HC_more(Highcharts); //init module
// require("highcharts/modules/draggable-points")(Highcharts);
import PieChart from "highcharts-react-official";







Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
});


const monthLabels = ["Jun 2019",
  "May 2019",
  "Apr 2019"
];


const quota = {

  2: [
    ['Discovery', 61],
    ['POC', 41],
    ['Tech Win', 45],
    ['Tech Loss', 231]
  ],
  1: [
    ['Discovery', 38],
    ['POC', 56],
    ['Tech Win', 49],
    ['Tech Loss', 212]
  ],
  0: [
    ['Discovery', 42],
    ['POC', 61],
    ['Tech Win', 62],
    ['Tech Loss', 158]
  ]
};




const data = {
  2: [
    ['Discovery', 313488],
    ['POC', 131000],
    ['Tech Win', 120234],
    ['Tech Loss', 887213]
  ],
  1: [
    ['Discovery', 113488],
    ['POC', 131000],
    ['Tech Win', 120234],
    ['Tech Loss', 787213]
  ],
  0: [
    ['Discovery', 213488],
    ['POC', 131000],
    ['Tech Win', 120234],
    ['Tech Loss', 687213]
  ]
};

const lbl = [{
  name: 'Discovery',
  color: '#b3b7bd'
}, {
  name: 'POC',
  color: '#1a88f0'
}, {
  name: 'Tech Win',
  color: '#02c880'
}, {
  name: 'Tech Loss',
  color: '#ff6361'
}];


const getData = data => data.map((month, i) => ({
  name: month[0],
  y: month[1],
  color: lbl[i].color
}));

const optionsMainChart = {
  chart: {
    type: 'column',
    height: 425
  },
  title: {
    text: ''
  },

  tooltip: {
    shared: true,
    useHTML: true
  },

  plotOptions: {
    series: {
      dragDrop: {
        // draggableY: true,
        dragPrecisionY: 1
      },
      // stickyTracking: false,
      grouping: false,
      borderWidth: 0
    },
    column: {

      borderRadius: 5,
      minPointLength: 2
    },
    line: {
      cursor: 'ns-resize'
    }
  },
  legend: {
    enabled: false
  },
  xAxis: {
    type: 'category',
    max: 3,
    labels: {
      useHTML: true,
      animate: true,
      formatter: ctx => {
        let icon;
        let name;

        lbl.forEach(function (label) {
          if (label.name === ctx.value) {
            name = label.name;
          }
        });

        return `<span>${name}</span>`;
      },
      style: {
        textAlign: 'center',
        fontFamily: 'Libre Franklin, sans-serif',
        fontSize: '12.5px'
      }
    }
  },
  yAxis: [{
    allowDecimals: false,
    minRange: 1,
    title: {
      text: ''
    },
    softMin: 0,
    softMax: 400,
    opposite: true
  }, {
    allowDecimals: false,
    minRange: 1,
    title: {
      text: ''
    },
    softMin: 0,
    softMax: 400
  }],
  series: [{
    name: monthLabels[0],
    id: 'main',

    tooltip: {
      pointFormat: '<span style="color:#000000">{series.name} revenue</span>: <b>${point.y:,.0f}</b><br/>',
    },
    yAxis: 1,
    dataLabels: [{
      name: 'Total Amount',
      format: '${point.y:,.0f}',
      enabled: true,
      allowDecimals: false,
      minRange: 1,
      dataLabels: [{
        format: '${point.y:,.0f}'
      },],
      // inside: true,
      style: {
        fontSize: '16px',
        color: '#495057',
        fontFamily: 'Libre Franklin, sans-serif'
      }
    }],
    data: getData(data[0]).slice()
  }, {
    title: {
      text: ''
    },
    color: '#000000',
    linkedTo: 'main',
    data: quota[0].slice(),
    name: '',

    tooltip: {
      pointFormat: '<span style="color:{series.color}">{series.name} time spent</span>: <b>{point.y} hrs</b><br/>',
    },
    type: 'spline',
    dataLabels: [{
      format: '{point.y:,.0f} hrs'
    }
    ]
  }],
  exporting: {
    allowHTML: true
  },
  credits: {
    enabled: false
  },
};
const optionsPie = {
  chart: {
    type: "pie",
    height: 300,
    style: {
      textAlign: 'center',
      fontFamily: 'Libre Franklin, sans-serif',
      fontSize: '12.5px'
    },
  },
  title: {
    text: ""
  },
  credits: {
    enabled: false
  }, tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    fontFamily: 'Libre Franklin, sans-serif',
    fontWeight: 200,
  },

  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        color: "#495057",
        fontFamily: 'Libre Franklin, sans-serif',
        fontWeight: 100,
      },
      showInLegend: false
    }
  },
  series: [
    {
      name: "Requests",
      color: "#006600",
      innerSize: '70%',
      lineWidth: 1,
      marker: {
        enabled: false,
        symbol: "circle",
        radius: 2,
        states: {
          hover: {
            enabled: true,
            lineWidth: 1
          }
        }
      },
      data: [
        {
          name: "In progress",
          y: 0.069,
          color: '#1a88f0'
        },
        {
          name: "Done",
          y: 0.026,

          color: '#02c880'
        },
        {
          name: "Won't Do",
          y: 0.573,
          color: '#ff6361'
        },
        {
          name: "Not Started",
          y: 0.332,
          color: '#b3b7bd'
        }
      ]
    }
  ]
};

window.Highcharts = Highcharts;

class Dashboard extends Component {


  constructor(props) {
    super(props);

    this.chartOptions = optionsMainChart;
    this.pieOptions = optionsPie;
    this.state = {

      reports: [
        { title: "Demos Run", iconClass: "bx bxs-chalkboard", description: "0" },
        {
          title: "Time Invested",
          iconClass: "bx bxs-time",
          description: "0 hrs",
        },
        {
          title: "Revenue Influenced",
          iconClass: "bx bxs-dollar-circle",
          description: "$0",
        },
      ],
      email: [
        { title: "Week", linkto: "#", isActive: false },
        { title: "Month", linkto: "#", isActive: false },
        { title: "Year", linkto: "#", isActive: true },
      ],
      modal: false,
      subscribemodal: false,
      chartSeries: [],
      periodType: "Jun 2019"
    };

    this.togglemodal.bind(this);
    this.togglesubscribemodal.bind(this);
  }

  componentDidMount() {
    const { onGetChartsData } = this.props;
    setTimeout(() => this.setState({ subscribemodal: true }), 2000);
  }


  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };



  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }));
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ ...this.state, chartSeries: this.props.chartsData });
    }
  }

  render() {



    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Strive</title>
          </MetaTags>
          <Container fluid>

            {/* Render Breadcrumb */}
            <Breadcrumbs title="Strive" breadcrumbItem="Dashboard" />
            <Row>
              <Col xl="4">
                <WelcomeComp />
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Product Requests</CardTitle>


                    <PieChart highcharts={Highcharts} options={this.pieOptions} chartRef={this.chartRef} />
                    <Row className="justify-content-center">
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20 text-danger">784</h5>
                          <p className="bg-danger badge bg-danger">Deal Blockers</p>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20 text-warning">421</h5>
                          <p className="bg-warning badge bg-warning">Deal Risks</p>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20 text-secondary">123</h5>
                          <p className="bg-secondary badge bg-secondary">Nice to Have</p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="8">
                <Row>
                  {/* Reports Render */}
                  {this.state.reports.map((report, key) => (
                    <Col md="4" key={"_col_" + key}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>

                <Card>
                  <CardBody>
                    <div className="d-sm-flex flex-wrap">
                      <CardTitle className="card-title mb-4 h4">
                        Pre-Sales Opportunities
                      </CardTitle>
                      <div className="ms-auto">
                        <ul className="nav nav-pills">
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { "active": this.state.periodType === "Apr 2019" },
                                "nav-link"
                              )}
                              onClick={() => {
                                this.setState({ ...this.state, periodType: "Apr 2019" });
                                this.chartOptions = {
                                  series: [{
                                    name: "",

                                    data: getData(data[2]).slice()
                                  }, {
                                    name: "",
                                    data: quota[2].slice()
                                  }]
                                };
                              }}
                              id="one_month"
                            >
                              {monthLabels[2]}
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { "active": this.state.periodType === "May 2019" },
                                "nav-link"
                              )}
                              onClick={() => {
                                this.setState({ ...this.state, periodType: "May 2019" });
                                this.chartOptions = {
                                  series: [{
                                    name: "",

                                    data: getData(data[1]).slice()
                                  }, {
                                    name: "",
                                    data: quota[1].slice()
                                  }]
                                };
                              }}
                              id="one_month"
                            >
                              {monthLabels[1]}
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { "active": this.state.periodType === "Jun 2019" },
                                "nav-link"
                              )}
                              onClick={() => {
                                this.setState({ ...this.state, periodType: "Jun 2019" });
                                this.chartOptions = {
                                  series: [{
                                    name: "",
                                    data: getData(data[0]).slice()
                                  }, {
                                    name: "",
                                    data: quota[0].slice()
                                  }]
                                };
                              }}
                              id="one_month"
                            >
                              {monthLabels[0]}
                            </Link>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="clearfix" />
                    {/* <StackedColumnChart chartSeries={this.state.chartSeries} /> */}
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={this.chartOptions}
                      ref={this.chartRef}

                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xl="4">
                <ActivityComp />
              </Col>
              <Col xl="4">
                <Comments />
              </Col>
              <Col xl="4">
                <TopCities />
              </Col>
            </Row>
          </Container>
        </div>


        <Modal
          isOpen={this.state.modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabindex="-1"
          toggle={this.togglemodal}
        >
          <div className="modal-content">
            <ModalHeader toggle={this.togglemodal}>Order Details</ModalHeader>
            <ModalBody>
              <p className="mb-2">
                Product id: <span className="text-primary">#OP2540</span>
              </p>
              <p className="mb-4">
                Billing Name:{" "}
                <span className="text-primary">Neal Matthews</span>
              </p>

              <div className="table-responsive">
                <Table className="table align-middle table-nowrap">
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <div>
                          <img src={modalimage1} alt="" className="avatar-sm" />
                        </div>
                      </th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14">
                            Solid Color T-Shirt
                          </h5>
                          <p className="text-muted mb-0">$ 225 x 1</p>
                        </div>
                      </td>
                      <td>$ 255</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div>
                          <img src={modalimage2} alt="" className="avatar-sm" />
                        </div>
                      </th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14">
                            Hoodie (Blue)
                          </h5>
                          <p className="text-muted mb-0">$ 145 x 1</p>
                        </div>
                      </td>
                      <td>$ 145</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-right">Sub Total:</h6>
                      </td>
                      <td>$ 400</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-right">Shipping:</h6>
                      </td>
                      <td>Free</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-right">Total:</h6>
                      </td>
                      <td>$ 400</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={this.togglemodal}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func
};

const mapStateToProps = ({ Dashboard }) => ({
  chartsData: Dashboard.chartsData,
});

const mapDispatchToProps = dispatch => ({
  onGetChartsData: (periodType) => dispatch(getChartsData(periodType)),
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(withTranslation()(Dashboard));
